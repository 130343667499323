<template>
  <section id="user-info" class="user-info" :class="theme">
    <div class="avatar-wrapper">
      <avatar
        v-if="session && session.name"
        :username="session.name"
        :size="36"
        :lighten="100"
        class="shadow-md cursor-pointer avatar"
      />
      <div
        class="h-3 w-3 border-white border border-solid rounded-full absolute pin-r pin-b"
        :class="
          'bg-' + `${activeUserInfo.status === 'online' ? 'success' : 'danger'}`
        "
      />
    </div>
    <div class="text-wrapper" v-if="!sidebarItemsMin">
      <p class="font-semibold" v-if="session && session.name">
        {{ session.name }}
      </p>
      <span v-if="session && session.roles && session.roles[0]">
        {{ session.roles[0] }}
      </span>
      <small>
        {{ lang.navbar.header.userStatus.youAre[languageSelected] }}
        <strong>
          {{
            lang.navbar.header.userStatus[activeUserInfo.status][
              languageSelected
            ]
          }}.
        </strong>
        <a @click="toggleStatus" class="status-change">
          {{ lang.navbar.header.userStatus.set[languageSelected] }}
        </a>
      </small>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { themeConfig } from '../../../../themeConfig'

export default {
  name: 'UserInfo',
  data() {
    return {
      themeConfig
    }
  },
  components: {
    Avatar: () => import('vue-avatar')
  },
  computed: {
    ...mapState([
      'session',
      'theme',
      'lang',
      'languageSelected',
      'sidebarItemsMin'
    ]),
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    toggleStatus() {
      if (this.$store.state.AppActiveUser.status === 'online') {
        this.$store.state.AppActiveUser.status = 'offline'
      } else {
        this.$store.state.AppActiveUser.status = 'online'
      }
    }
  }
}
</script>

<style lang="scss">
.user-info {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;

  &.semi-dark {
    color: white;
  }

  .avatar-wrapper {
    position: relative;
    display: flex;
    .avatar {
      border: solid 1px rgba(255, 255, 255, 0.8);
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 10px;
    font-size: 0.85rem;

    .status-change {
      cursor: pointer;
    }
  }
}
</style>
